.unauth-container {
  height: 22rem;
  width: 35rem;
  background-color: #212930;
  border-radius: 0.4rem;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;



  .horizon-logo {
    width: 30rem;
  }
}
