.sign-out-btn {
  float: center;
  color: #fff;
  padding: 0rem;
  margin-bottom: 2rem;
  cursor: pointer;
  font-size: 1rem;

  .sign-out-img {
    text-align: left;
    font-size: 1rem;
    vertical-align: middle;
    margin-top: 0.2rem;
    color: #FF6C00;
  }

  .logout-link {
    text-align: center;
    color: #FF6C00;
    cursor: pointer;
  }

  .sign-out-text {
    text-align: center;
    vertical-align: middle;
    color: #FF6C00;
    padding-left: 0.5rem;
  }
}
