.splash-screen {
  background-color: #212930;
  padding: 0rem;
  width: 100%;
  height: 100%;

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;

    .horizon-logo {
      width: 75%;
      margin-bottom: 10rem;
    }
  }
}

.auth-container {
  width: 35rem;
  background-color: #212930;
  border-radius: 0.4rem;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .welcome-text {
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 6rem;
  }

  .scope-btn {
    text-align: center;
    border: 1px solid #8497b0;
    margin: 1rem;
    background-color: #333f50;
    border-radius: 3px;
    color: #fff;
    padding: 1rem;
    cursor: pointer;

    .scope-client-text {
      font-size: 1rem;
      padding-bottom: 0.5rem;
    }

    .scope-scope-text {
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .horizon-logo {
    width: 30rem;
    margin-top: 1rem;
  }
}
