.login-btn {
  text-align: center;
  border: 1px solid #8497b0;
  width: 18rem;
  background-color: #333f50;
  border-radius: 3px;
  color: #fff;
  padding: 0.6rem;
  margin-top: 1rem;
  cursor: pointer;

  .ms-icon {
    width: 1.25rem;
    margin-left: 0.1rem;
    float: left;
    left: 0;
    position: relative;
  }

  .login-text {
    text-align: center;
  }
}
